.login-form {
  background: #494b4f;
  border: 1px solid #000;
  flex-flow: column;
  justify-self: center;
  gap: .5em;
  width: 300px;
  margin: 10em auto auto;
  padding: 2em;
  display: flex;
}

.login-form input {
  color: #ccc;
  background: #363e40;
}
/*# sourceMappingURL=index.08723167.css.map */
