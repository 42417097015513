.login-form
	display: flex
	flex-flow: column nowrap
	gap: 0.5em
	margin: auto
	margin-top: 10em
	justify-self: center
	padding: 2em
	background: #494b4f
	border: 1px solid black
	width: 300px
	input
		background: #363e40
		color: #ccc